<template>
  <div class="row">
    <b-modal id="currency-rates" ref="currency-rates" hide-footer hide-header hide-header-close scrollable size="xl">
      <currency-rates @exchange="selectedExchangeRate" v-if="showCurrencies"/>
    </b-modal>

    <div class="col-md-12">
      <div id="filter-card" class="row justify-content-start items-baseline w-100 mb-2" style="margin-bottom: -5px;">
        <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
          <i class="fa fa-chevron-left"></i>
        </span>
        <h3 id="page-title" class="d-inline-block">{{ $t('invoice.outgoing_invoice') }}</h3>
        <div class="flex-grow-1 px-3 d-flex flex-row-reverse">
          <div class="d-inline-block">
            <button-with-icon
                @onClick="showCurrencyRates()"
                icon-name=""
                class="bg-default text-primary"
                size="md"
                :text="$t('invoice.currency_rates')"
            ></button-with-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 h-50vh">
      <dashboard-box>
        <template v-slot:preview>
          <div class="px-5 py-3">
            <div class="row items-start">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                <div class="col-12 d-flex justify-content-start">
                  <custom-multi-select
                      :is-inline="false"
                      :max="1"
                      :model.sync="form.invoice_type_id"
                      :not-list="true"
                      :options="options.invoiceTypeOptions"
                      :required="true"
                      :title="$t('invoice.invoice_type')"
                      class="mr-3"
                      name="invoice_type"
                  ></custom-multi-select>
                  <custom-multi-select
                      :disabled="!form.invoice_type_id"
                      :is-inline="false"
                      :max="1"
                      :model.sync="form.invoice_category_id"
                      :not-list="true"
                      :options="options.invoiceCategoryOptions"
                      :required="true"
                      :title="`Tipi`"
                      class="mr-3"
                      name="invoice_kind"
                      @onSelect="onCategoryChange"
                  ></custom-multi-select>
                </div>
                <div v-if="form.parent_invoice" class="col-12">
                  <text-input
                      :disabled="true"
                      :model.sync="form.parent_invoice"
                      :required="true"
                      :title="$t('invoice.parent_name_invoice')"
                  ></text-input>
                </div>
                <div v-if="form.invoice_category_id" class="col-12 items-start">
                  <date-picker-input
                      :disabled="true"
                      :model="today"
                      :required="false"
                      :title="$t('invoice.date')"
                  ></date-picker-input>
                </div>
                <div class="col-12">
                  <custom-multi-select
                      :is-inline="false"
                      :model.sync="form.packing_list_ids"
                      :not-list="false"
                      :options="options.packingLists"
                      :required="false"
                      title="Çeki Listeleri"
                      :show-invalid="false"
                      class="mr-3"
                      name="invoice_packing_list"
                  ></custom-multi-select>
                </div>
                <div class="col-12 d-lg-none d-md-flex mb-3 ">
                  <div class="w-200px">
                    <span class="text-lg">
                      {{ $t('invoice.add_withholding') }}
                    </span>
                  </div>
                  <div v-if="form.invoice_category_id" class="flex-grow-1">
                    <b-form-checkbox
                        id="invoice_withholding"
                        v-model="form.is_withholding"
                        class="checkbox"
                        name="invoice_withholding"
                        unchecked-value="0"
                        value="1"
                    ></b-form-checkbox>
                  </div>
                </div>
              </div>
              <div v-if="form.invoice_category_id"
                   class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                <div class="col-12">
                  <custom-multi-select
                      :item-per-row="1"
                      :max="1"
                      :model.sync="form.supplier_company_id"
                      :not-list="true"
                      :options="options.companyOptions"
                      :required="true"
                      :show-invalid="true"
                      :title="$t('invoice.company_name')"
                      name="invoice_company_name"
                  ></custom-multi-select>
                </div>
                <div class="col-12">
                  <date-picker-input
                      :model.sync="form.invoice_date"
                      :required="true"
                      :title="$t('invoice.invoice_date')"
                  ></date-picker-input>
                </div>
                <div class="col-12">
                  <custom-multi-select
                      :disabled="!form.supplier_company_id"
                      :is-inline="false"
                      :model.sync="form.waybill_ids"
                      :not-list="false"
                      :options="options.waybills"
                      :required="false"
                      :title="`İrsaliye`"
                      :show-invalid="false"
                      class="mr-3"
                      name="invoice_waybill"
                  ></custom-multi-select>
                </div>
              </div>
              <div v-if="form.invoice_category_id"
                   class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                <div class="col-12 mb-3 d-lg-flex d-md-none">
                  <div class="w-200px">
                    <span class="text-lg">
                      {{ $t('invoice.add_withholding') }}
                    </span>
                  </div>
                  <div class="flex-grow-1">
                    <b-form-checkbox
                        id="invoice_withholding"
                        v-model="form.is_withholding"
                        class="checkbox"
                        name="invoice_withholding"
                        unchecked-value="0"
                        value="1"
                    ></b-form-checkbox>
                  </div>
                </div>
                <div v-if="+form.is_withholding === 1" class="col-12 d-lg-block d-md-none">
                  <custom-multi-select
                      :max="1"
                      :required="false"
                      :model.sync="form.vat_deduct_id"
                      :not-list="true"
                      :options="options.vatDeducts"
                      :title="$t('invoice.vatDeduct')"
                      name="vat_deduct_id"
                      :show-invalid="false"
                  ></custom-multi-select>
                </div>
                <div v-if="+form.is_withholding === 1" class="col-12 d-lg-block d-md-none">
                  <text-input
                      :helper-text="$t('general.please_enter_manually')"
                      :is-inline="true"
                      :model.sync="form.withholding_share"
                      :placeholder="''"
                      :title="$t('invoice.withholding_share')"
                      class="font-weight-bolder"
                      input-width="125px"
                      name="withholding_share"
                  ></text-input>
                  <text-input
                      :helper-text="$t('general.please_enter_manually')"
                      :is-inline="true"
                      :model.sync="form.withholding_denominator"
                      :placeholder="''"
                      :title="$t('invoice.withholding_denominator')"
                      class="font-weight-bolder"
                      input-width="125px"
                      name="withholding_denominator"
                  ></text-input>
                </div>
                <div v-if="showVatExceptionField" class="col-12 d-lg-block d-md-none">
                  <custom-multi-select
                      :max="1"
                      :required="false"
                      :model.sync="form.vat_exception_id"
                      :not-list="true"
                      :options="options.vatExceptions"
                      :title="$t('invoice.vatException')"
                      name="vat_exception_id"
                  ></custom-multi-select>
                </div>
                <div v-if="showVatExceptionField" class="col-12 d-lg-block d-md-none">
                  <text-input
                      :helper-text="$t('please_enter_manually')"
                      :is-inline="true"
                      :model.sync="form.vat_exception_description"
                      :placeholder="''"
                      :title="$t('invoice.description')"
                      class="font-weight-bolder"
                      input-width="125px"
                      name="vat_exception_description"
                  ></text-input>
                </div>
              </div>
            </div>
            <div v-show="isValidToContinue" class="col-12 p-0 m-0 text-primary h-20px">
              <span class="float-right" @click="addNewRow" v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-35px h-35px d-inline-block object-fill cursor-pointer'
                })">
             </span>
            </div>

            <div v-show="isValidToContinue" class="w-100 col-12 border-15px navy-blue-border-1px mx-3 mt-5 mb-1">
              <table class="table w-100 border-15px mb-0">
                <thead class="w-100">
                <tr>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px" >
                    <div class="text-right text-lg pr-10">
                      {{ $t('invoice.instruction_type') }}
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px" v-if="checkVisibleOrderColumn">
                    <div class="text-right text-lg pr-10">
                      {{ $t('invoice.order_number') }}
                    </div>
                  </th>

                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px" v-if="false">
                    <div class="text-right text-lg pr-10">
                      {{ $t('invoice.product') }}
                    </div>
                  </th>

                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px" v-if="showInstructionLogoType && instructionLogoTypeVisible">
                    <div class="text-right text-lg pr-10">
                      {{ $t('invoice.instruction_logo_type') }}
                    </div>
                  </th>

                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px" v-if="checkVisibleCodeColumn">
                    <div class="text-right text-lg pr-10">
                      {{ $t('invoice.code') }}
                    </div>
                  </th>

                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('invoice.code_name') }}
                      <info-tooltip
                          :helper-text="$t('invoice.please_enter_manually')"
                      ></info-tooltip>
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('invoice.amount') }}
                      <info-tooltip
                          :helper-text="$t('invoice.please_enter_manually')"
                      ></info-tooltip>
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('invoice.unit') }}
                      <info-tooltip
                          :helper-text="$t('invoice.please_enter_manually')"
                      ></info-tooltip>
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('invoice.unit_price') }}
                      <info-tooltip
                          :helper-text="$t('invoice.please_enter_manually')"
                      ></info-tooltip>
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('invoice.vat_amount') }}
                      <info-tooltip
                          :helper-text="$t('invoice.please_enter_manually')"
                      ></info-tooltip>
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('invoice.index_amount') }}
                      <info-tooltip
                          :helper-text="$t('invoice.please_enter_manually')"
                      ></info-tooltip>
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('invoice.amount_total') }}
                      <info-tooltip
                          :helper-text="$t('invoice.please_enter_manually')"
                      ></info-tooltip>
                    </div>
                  </th>

                  <th class="navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('order.description') }}
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody class="navy-blue-bottom-1px">
                <template v-for="(item, key) in transactions">
                  <tr>
                    <td class="navy-blue-border-right-1px" v-if="item.instruction_type_id">
                      <custom-multi-select
                          :disabled="false"
                          :is-inline="false"
                          :max="1"
                          :model.sync="item.instruction_type_id"
                          :not-list="true"
                          :options="options.instructionTypeOptions"
                          :required="false"
                          name="instruction_type"
                          title=""
                          @save="instructionTypeSave($event, key)"
                      ></custom-multi-select>
                    </td>
                    <td class="navy-blue-border-right-1px" v-if="item.waybillable">
                      <input v-model="item.waybillable.order_number" class="form-control d-inline-block mb-5" type="text" :disabled="true">
                    </td>
                    <td class="navy-blue-border-right-1px" v-if="false">
                      <custom-multi-select
                          :has-bottom-margin="false"
                          :disabled="true"
                          :is-inline="false"
                          :model.sync="item.waybillable_ids"
                          :options="item.orderOptions"
                          :required="false"
                          input-width="150px"
                          max="1"
                          name="waybillable_ids"
                          object-key="name"
                          title=""
                          @save="waybillableIdsChanged($event, key)"
                      />
                    </td>

                    <td class="navy-blue-border-right-1px" v-if="false">
                      <div v-if="item.waybillable && item.waybillable.product_name"
                           class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                        <custom-multi-select
                            :is-inline="false"
                            :disabled="true"
                            :model.sync="item.order_product_id"
                            :options="item.waybillable.detail"
                            :placeholder="$t('waybill.please_enter_product_color')"
                            :required="false"
                            max="1"
                            name="wayBillOrderColor"
                            not-list="true"
                            object-key="color"
                            title=""
                            @save="onSelectProduct($event, key)"
                        >
                        </custom-multi-select>
                      </div>
                    </td>

                    <td class="navy-blue-border-right-1px" v-if="showInstructionLogoType && instructionLogoTypes">
                      <custom-multi-select
                          :is-inline="false"
                          :disabled="item.waybillable_type"
                          :max="1"
                          :model.sync="item.line_code"
                          :not-list="true"
                          :options="instructionLogoTypes"
                          :required="false"
                          title=""
                          class="mr-3"
                          name="instruction_logo_type"
                          @onSelect="instructionLogoTypeSave($event, key)"
                      ></custom-multi-select>
                    </td>
                    <td class="navy-blue-border-right-1px">
                      <input v-model="item.line_code" class="form-control d-inline-block mb-5" type="text" :disabled="true">
                    </td>
                    <td class="navy-blue-border-right-1px items-start">
                      <input v-model="item.line_code_name" class="form-control d-inline-block mb-5" type="text" :disabled="true">
                    </td>
                    <td class="navy-blue-border-right-1px items-start">
                      <input v-model="item.amount" class="form-control d-inline-block mb-5" type="number">
                    </td>

                    <td class="navy-blue-border-right-1px items-start">
                      <custom-multi-select
                          :input-width="`65px`"
                          :is-inline="false"
                          :item-per-row="1"
                          :max="1"
                          :model.sync="item.amount_unit_id"
                          :not-list="true"
                          :options="unitOptions[key]"
                          :required="false"
                          :title="null"
                          name=""
                      ></custom-multi-select>
                    </td>
                    <td class="navy-blue-border-right-1px">
                      <input v-model="item.unit_price" class="form-control form-control-sm d-inline-block" step="1"
                             type="number">
                    </td>
                    <td class="navy-blue-border-right-1px">
                      <custom-multi-select
                          :max="1"
                          :model.sync="item.vat_rate_id"
                          :not-list="true"
                          :options="vatRateOptions"
                          :required="false"
                          name="transaction_vat_rate_id"
                          title=""
                      ></custom-multi-select>

                      <span class="font-weight-boldest"> {{ item.vat_price | priceFormat }} {{ selectedCurrency }} </span>
                    </td>
                    <td class="navy-blue-border-right-1px">
                      {{ item.price | priceFormat }} {{ selectedCurrency }}
                    </td>
                    <td class="navy-blue-border-right-1px">
                     {{ item.total_price | priceFormat }} {{ selectedCurrency }}
                    </td>
                    <td class="">
                      <input v-model="item.description" class="form-control form-control-sm" type="text">
                    </td>
                  </tr>
                </template>

                </tbody>
              </table>
            </div>

            <div v-if="isValidToSave"
                 class=" float-right w-50 lg:w-35 md:w-50 sm:w-50 py-3 px-5 mt-12 border-15px navy-blue-border-1px bg-white">
              <invoice-layout
                  :buying-type-options="options.receiveTypeOptions"
                  :buying_type_id.sync="form.invoice_receiving_type_id"
                  :currency-options="options.currencyOptions"
                  :currency-rates="currencyRateOptions"
                  :currency_unit_id.sync="form.currency_unit_id"
                  :discount.sync="form.total_discount"
                  :exchange.sync="form.exchange_rate"
                  :item="form"
                  :transactions="transactions"
                  :vat-options="options.vatOptions"
                  :vat-rates="vat_rates"
              ></invoice-layout>
            </div>

            <div class="row px-5 py-3" v-if="isValidToSave">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                Açıklamalar
                <span @click="addWaybillDescriptionGeneral()"
                      v-html="getIconByKey('icons.waybill.add_document', {
                          class: 'w-25px h-25px m-0 p-0 object-cover d-inline-block opacity-75 mr-2',
                          })">
                  </span>
              </div>

              <template v-for="(item, index) in form.description">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0">
                <text-input
                    :has-bottom-margin="false"
                    :helper-text="$t('waybill.general_description')"
                    :is-inline="false"
                    :is-valid="form.description[index] == null ? null : true"
                    :model.sync="form.description[index]"
                    :placeholder="$t('waybill.general_description')"
                    :required="false"
                    title=""
                    :maxlength="50"
                    label-class="text-lg"
                ></text-input>
                <span @click="deleteWaybillDescriptionGeneral(index)"
                      v-html="getIconByKey('icons.sample.delete_red', {
                          class: 'w-25px h-25px m-0 p-0 object-cover d-inline-block opacity-75 mr-2',
                          })">
                  </span>
              </div>
            </template>
            </div>
            <div class="col-12 d-flex justify-content-center my-3 mt-10 pt-5">
              <button-with-icon
                  :disabled="!isValidToSave"
                  :icon-name="null"
                  :text="$t('invoice.save')"
                  class="w-200px text-primary"
                  size="lg"
                  @onClick="submitInvoice"
              ></button-with-icon>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import TextInput from "@/assets/components/inputs/TextInput";
import {mapGetters, mapMutations} from 'vuex'
import moment from "moment";

import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import NumberInput from "@/assets/components/inputs/NumberInput";
import FileInput from "@/assets/components/inputs/FileInput";
import DataTable from "@/assets/components/dataTable/DataTable";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";

import {CREATE_ITEM, GET_ITEM_DETAIL_BY_ID, GET_ITEMS, LOADING, RESET_VALUES} from "@/core/services/store/REST.module";
import {LARAVEL_DATE_FORMAT} from "@/core/config/constant";
import SelectInput from "@/assets/components/inputs/SelectInput";
import InvoiceLayout from "@/view/pages/invoice/layouts/InvoiceLayout";
import CurrencyRates from "@/view/pages/invoice/layouts/CurrencyRates";

export default {
  name: "InvoiceCreateEdit",
  components: {
    InvoiceLayout,
    SelectInput,
    FileInput,
    NumberInput,
    ButtonWithIcon,
    DatePickerInput,
    CustomMultiSelect,
    DashboardBox,
    DataTable,
    InfoTooltip,
    TextInput,
    CurrencyRates
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    vatRateOptions() {
      let options = this.vatOptions || {};
      let data = options.filter(item => +item.type_id === 4)
      return this.convertArrayToObjectByKey(data, 'id', 'vat.rate');
    },
    vat_rates() {
      let self = this;
      return _.map(_.groupBy(this.transactions, 'vat_rate_id'), (o, idx) => {
        let vatRate = self.options.vatOptions[idx]
        return {
          rate: vatRate, sum: _.sumBy(o, 'vat_price')
        }
      })
    },
    withholdingRate() {
      if (this.form.withholding_denominator && this.form.withholding_share) {
        return this.form.withholding_share / this.form.withholding_denominator * 100;
      }
      return 0;
    },
    showInstructionLogoType() {
      return this.transactions.find((item) => item.waybillable_type === null)
    },
    selectedCurrency() {
      if (this.form.currency_unit_id) {
        return this.options.currencyOptions[this.form.currency_unit_id]
      }
      return "";
    },
    checkVisibleColumn(){
      let visible=true;
     if(this.form.invoice_category_id == 7 && this.transactions.length > 0 && this.transactions[0].waybillable == null ){
          visible=false;
      }else if(this.form.invoice_category_id == 1){
          visible=false;
      }

      return visible;
    },
    checkVisibleOrderColumn(){
      let visible=true;
     if(this.form.invoice_category_id == 7 && this.transactions.length > 0 && this.transactions[0].waybillable == null ){
          visible=false;
      }else if(this.form.invoice_category_id == 1 && this.transactions.length > 0 && this.transactions[0].waybillable == null){
          visible=false;
      }
      else if((this.form.invoice_category_id == 3 || this.form.invoice_category_id == 5) && this.transactions.length > 0 && this.transactions[0].waybillable == null){
          visible=false;
      }

      return visible;
    },
    checkVisibleCodeColumn(){
      let visible=true;
      if(this.form.invoice_category_id == 7 && this.transactions.length > 0 && this.transactions[0].waybillable == null ){
            visible=true;
        }else if(this.form.invoice_category_id == 1){
            visible=true;
        }

      return visible;
    },
    instructionLogoTypeVisible(){
      let visible=true;
      if(this.form.invoice_category_id == 7 && this.transactions.length > 0 && this.transactions[0].waybillable == null ){
            visible=false;
        }else if(this.form.invoice_category_id == 1 && this.transactions.length > 0 && this.transactions[0].waybillable == null){
            visible=false;
        }
        else if((this.form.invoice_category_id == 3 || this.form.invoice_category_id == 5) && this.transactions.length > 0 && this.transactions[0].waybillable == null){
            visible=false;
        }

        return visible;
    },
    withholdingAmount() {
      if (this.calculatedVat && this.form.withholding_share && this.form.withholding_denominator) {
        return this.calculatedVat * this.form.withholding_share / this.form.withholding_denominator;
      }
      return 0;
    },
    total() {
      let sum = 0;
      this.transactions.forEach(item => {
        sum += item.amount * item.unit_price;
      })
      return sum;
    },
    showVatExceptionField() {
      let result = false;
      let self = this;
      this.transactions.forEach(item => {
        if (item.vat_rate_id) {
          let vatRate = self.options.vatOptions[item.vat_rate_id]
          if (vatRate === 0) {
            result = true;
          }
        }
      })

      return result;
    },
    calculatedVat() {
      let sum = 0;
      let self = this;
      this.transactions.forEach(item => {
        sum += item.vat_price;
      })
      return sum;
    },
    totalAmountWithTaxes() {
      return this.total + this.calculatedVat + this.withholdingAmount;
    },
    totalAmount() {
      return this.total + this.calculatedVat + this.withholdingAmount - this.form.total_discount;
    },
    isValidToContinue() {
      if (Number(this.form.invoice_type_id) === 0) return false;
      if (Number(this.form.invoice_category_id) === 0) return false;
      if (Number(this.form.supplier_company_id) === 0) return false;
      return true;
    },
    isValidToSave: function () {
      if (!this.isValidToContinue) return false;
      let returnValue = true;
      let transactions = this.transactions;
      transactions.forEach(item => {
        if (!item.line_code || !item.line_code_name || !item.amount || +item.amount === 0 || !item.amount_unit_id || !item.unit_price) {
          returnValue = false;
          return false;
        }
      });
      return returnValue;
    },
  },
  data() {
    return {
      id: null,
      route_type: null,
      unitOptions: [],
      unitOptionsRaw: [],
      instructionTypeOptionsRaw: [],
      vatDeducts: [],
      vatExceptions: [],
      vatOptions: [],
      waybills: [],
      packingLists: [],
      currencyRateOptions: [],
      currencyRates: [],
      is_submitting: false,
      is_service: true,
      instructionLogoTypes: null,
      instructionLogoTypesRaw: [],
      today: moment(),
      currentHoveredItem: null,
      companyOptionsRaw: [],
      options: {
        companyOptions: [],
        waybills: [],
        packingLists: [],
        vatOptions: [],
        unitOptions: [],
        currencyOptions: [],
        vatDeducts: [],
        vatExceptions: [],
      },
      transactions: [],
      transaction: {
        vat_rate_id: null,
        waybillable_id: null,
        waybillable_type: null,
        instructable_id: null,
        instructable_type: null,
        order_product_id: null,
        order_size_and_color_id: null,
        instruction_type_id: null,
        line_code: null,
        line_code_name: null,
        amount: null,
        amount_unit_id: null,
        unit_price: null,
        unit_price_converted: null,
        vat_price: null,
        vat_price_converted: null,
        vat_rate: null,
        price: null,
        price_converted: null,
        total_price: null,
        total_price_converted: null,
        description: null,
        waybillable_ids: [],
        orderOptions: [],
        waybillable: null,
        instructable: null,
      },
      instruction_type_id: null,
      form: {
        vat_deduct_id: null,
        vat_deduct_description: "",
        vat_exception_description: "",
        vat_exception_id: null,
        is_withholding: '0',
        document_no: null,
        file_names: ['image'],
        supplier_company_id: null,
        invoice_type_id: null,
        invoice_category_id: null,
        invoice_no: null,
        total_discount: null,
        withholding_amount: null,
        withholding_share: null,
        withholding_denominator: null,
        withholding_code: null,
        vat_rate_id: null,
        image: null,
        overhead_invoice:null,
        invoice_date: moment().format(LARAVEL_DATE_FORMAT),
        waybill_ids: [],
        packing_list_ids: [],
        currency_unit_id: null,
        invoice_receiving_type_id: null,
        exchange_rate: 1,
        vat: null,
        vat_amount: null,
        amount: null,
        kdv_sum: null,
        sum: null,
        total_sum: null,
        packing_id: null,
        description: [],
        parent_invoice:null,
      },
      items: {data: []},
      showCurrencies: false,
    };
  },
  methods: {
    selectedExchangeRate(item,index,event){
      this.showCurrencies = true;
      this.$refs['currency-rates'].hide();
      this.showCurrencies = false;

      let currency_unit_id=null;

      currency_unit_id=Object.entries(this.options.currencyOptions).find(([key, val]) => {
        if(item.code===val){
          return key
        }
      });

      if(currency_unit_id){
        this.form.currency_unit_id=currency_unit_id[0]
        this.form.exchange_rate=item.rate;
      }else{
        this.form.currency_unit_id=null
        this.form.exchange_rate=null;
      }

    },
    onCategoryChange(value) {
      console.log(value)
      if (+value === 1 || +value === 3 || +value === 5) {
        this.getInstructionTypeCodes();
      } else if (+value === 7 ) {
        //this.getReturnInvoiceStockCards();
        this.getRejectInvoiceStockCards();
      }
    },
    showCurrencyRates() {
      this.showCurrencies = false;
      this.$refs['currency-rates'].show()
      this.showCurrencies = true;
    },
    addWaybillDescriptionGeneral() {
      if (this.form.description.length < 6) {
        this.form.description.push("");
      }
    },
    deleteWaybillDescriptionGeneral(key) {
      this.form.description.splice(key, 1)
    },
    getWaybills() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/waybills',
        filters: {
          invoiceable: true,
          transaction_type: 0,
          supplier_company_id: this.form.supplier_company_id
        }
      }).then(response => {
        if (response.status) {
          self.waybills = response.data.data;
          self.options.waybills = self.convertArrayToObjectByKey(response.data.data, 'id', 'document_number');
        }
      });
    },
    instructionLogoTypeSave(event, key) {
      let self = this;
      this.transactions[key].line_code = event;
      this.transactions[key].line_code_name = this.instructionLogoTypes[event];

      let instructionLogoType = this.instructionLogoTypesRaw.find(item => item.code === event);

      let instructionLogoTypeUnits =  instructionLogoType.units;

      let units = [];
      instructionLogoTypeUnits.forEach(item => {
        let unitItem = self.unitOptionsRaw.find(subItem => subItem.logo_name === item.uniT_CODE);

        if (unitItem) {
          units.push({
            id: unitItem.id,
            name: unitItem.logo_name
          })
        }
      })

      this.unitOptions[key] = self.convertArrayToObjectByKey(units, 'id', 'name');
    },
    instructionTypeSave(event, key) {
      if (event.newValue[0]) {
        this.transactions[key].waybillable = null;
        this.transactions[key].order_product_id = null;
        this.transactions[key].waybillable_ids = [];
        this.transactions[key].selectedInstructionTypeOption = this.instructionTypeOptionsRaw.find(item => +item.id === +event.newValue[0]);
        this.getWaybillOrderOptions(event.newValue[0], key);
      }
    },
    waybillableIdsChanged(event, key) {
      if (event.newValue[0]) {
        //this.transactions[key].waybillable = null;
        //this.transactions[key].order_product_id = null;
        this.transactions[key].selectedOrderOption = this.transactions[key].orderOptions[event.newValue[0]];
        //this.transactions[key].waybillable = this.transactions[key].orderOptions[event.newValue[0]];
      }
    },
    onSelectProduct(event, key) {
      let selectedItem = this.transactions[key].selectedOrderOption;
      let selectedItemDetail = selectedItem.detail[event.newValue[0]];
      this.transactions[key].waybillable_id = selectedItem.waybillable_id;
      this.transactions[key].waybillable_type = selectedItem.waybillable_type;
      this.transactions[key].instructable_id = selectedItemDetail.instructable_id
      this.transactions[key].instructable_type = selectedItemDetail.instructable_type;
      this.transactions[key].order_product_id = selectedItemDetail.order_product_id;
      this.transactions[key].amount_unit_id = selectedItemDetail.amount_unit_id;
      this.transactions[key].line_code = selectedItemDetail.color;
      this.transactions[key].line_code_name = selectedItemDetail.color;
      this.transactions[key].amount = selectedItemDetail.net_amount;
      this.transactions[key].unit_price = Number(selectedItemDetail.price);
    },
    getWaybillOrderOptions(instruction_type_id = null, orderIndex) {
      let instructionTypeId = instruction_type_id ? instruction_type_id : this.instruction_type_id;
      if (this.form.supplier_company_id != null && instructionTypeId != null) {
        this.$store.dispatch(GET_ITEMS, {
          url: 'api/waybill/waybill-exit-order-option',
          filters: {
            instruction_type_id: instructionTypeId,
            supplier_company_id: this.form.supplier_company_id
          },
          acceptPromise: true,
        }).then((result) => {
          let self = this;
          if (result.status) {
            self.transactions[orderIndex].orderOptions = result.data;
          }
        });
      }
    },
    getInstructionTypeCodes() {
      this.$store.dispatch(GET_ITEMS, {
          url: 'api/instructions/cache-data',
          filters: {}
      }).then((result) => {
          let self = this;
          if (result.status) {
            self.instructionLogoTypes = self.convertArrayToObjectByKey(result.data, 'code', 'name');
            self.instructionLogoTypesRaw = result.data;
          }
        });
    },
    getReturnInvoiceStockCards() {
      this.$store.dispatch(GET_ITEMS, {
          url: 'api/invoice/stock-cards/return',
          filters: {}
      }).then((result) => {
          let self = this;
          if (result.status) {
            self.instructionLogoTypes = self.convertArrayToObjectByKey(result.data, 'code', 'name');
            self.instructionLogoTypesRaw = result.data;
          }
        });
    },
    getRejectInvoiceStockCards() {
      this.$store.dispatch(GET_ITEMS, {
          url: 'api/invoice/stock-cards/reflect',
          filters: {}
      }).then((result) => {
          let self = this;
          if (result.status) {
            self.instructionLogoTypes = self.convertArrayToObjectByKey(result.data, 'code', 'name');
            self.instructionLogoTypesRaw = result.data;
          }
        });
    },
    openPopUpModal() {
      this.$modal.show('invoice-confirm-modal');
      this.didClickCancel = false;
    },
    addNewRow() {
      let transaction = _.cloneDeep(this.transaction);
      this.transactions.push(transaction);
    },
    submitInvoice() {
      if (!this.isUserGranted('Invoice', ['create'])) {
        return false;
      }
      if (this.is_submitting) return;
      this.is_submitting = true;

      let invoiceTransactions = this.transactions;

      let formData = new FormData();
      let self = this;
      let transactions = [];

      let exchangeRate = this.form.exchange_rate;

      let form = _.cloneDeep(this.form);
      invoiceTransactions.forEach((transaction, index) => {
        

        let tmpItem = {
          waybillable_id: transaction.waybillable_id || null,
          waybillable_type: transaction.waybillable_type || null,
          instructable_id: transaction.instructable_id || null,
          instructable_type: transaction.instructable_type || null,
          //order_product_id: transaction.order_product_id || null, değiştirildi
          order_product_id: null,
          order_size_and_color_id: null,
          instruction_type_id: transaction.instruction_type_id || null,
          line_code: transaction.line_code,
          line_code_name: transaction.line_code_name,
          vat_rate_id: transaction.vat_rate_id,
          vat_rate: transaction.vat_rate,
          amount: transaction.amount,
          amount_unit_id: transaction.amount_unit_id,
          unit_price: transaction.unit_price,
          unit_price_converted: transaction.unit_price * exchangeRate,
          vat_price: transaction.vat_price,
          vat_price_converted: transaction.vat_price * exchangeRate,
          price: transaction.price,
          price_converted: transaction.price * exchangeRate,
          exchange_rate: exchangeRate,
          total_price: transaction.total_price,
          total_price_converted: transaction.total_price * exchangeRate,
          description: transaction.description,
          waybill_transaction_id:null,
        };

        if(transaction.order_product_id){
          tmpItem.order_product_id=transaction.order_product_id;
        }else if(transaction.instructable&&transaction.instructable.order_fabric_id){
          tmpItem.order_product_id=transaction.instructable.order_fabric_id;
        }else{
          tmpItem.order_product_id=null;
        }

        if(transaction.waybill_transaction_id){
          tmpItem.waybill_transaction_id=transaction.waybill_transaction_id;
        }
        if(transaction.order_size_and_color_id){
          tmpItem.order_size_and_color_id=transaction.instructable.order_size_and_color_id;
        }else if(transaction.instructable&&transaction.instructable.order_size_and_color_id){
          tmpItem.order_size_and_color_id=transaction.instructable.order_size_and_color_id;
        }else{
          tmpItem.order_size_and_color_id=null;
        }
        
        if (transaction.id) {
          tmpItem.id = transaction.id;
        }

        transactions.push(tmpItem);
      })

      form.invoice_date = moment().format(LARAVEL_DATE_FORMAT);

      let tempForm = {
        ...form,
        is_service: 1,
        calculated_vat: this.calculatedVat,
        calculated_vat_converted: this.calculated_vat * exchangeRate,
        total_amount_including_taxes: this.totalAmountWithTaxes,
        total_amount_including_taxes_converted: this.total_amount * exchangeRate,
        total_discount: form.total_discount,
        total_discount_converted: this.form.total_discount  * exchangeRate,
        total_amount: this.total,
        total_amount_converted: this.total_amount * exchangeRate,
        amount_to_be_paid: this.totalAmount,
        amount_to_be_paid_converted: this.total_amount * exchangeRate,
        withholding_amount: this.withholdingAmount,
        transactions: transactions,
        waybills: form.waybill_ids,
        packing_list_ids: form.packing_list_ids,
        description: JSON.stringify(form.description)
      };

      this.createFormDataForList(formData, null, tempForm);

      this.$store.dispatch(CREATE_ITEM, {
        url: this.id === null ? 'api/invoice' : 'api/invoice/' + this.$route.query.id,
        contents: formData,
        showLoading: true,
      }).then(response => {
        if (response.status) {
          self.sweetAlertClose();
          self.is_submitting = false;
          setTimeout(function () {
            self.sweetAlertSuccess(self.$t('invoice.invoice_success_message')).then(() => {
              self.$router.push({name: 'invoice.outgoing'});
            })
          }, 500);
        } else {
          self.is_submitting = false;
          setTimeout(function () {
            let result = response.data.response;
            if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
              self.sweetAlertError(result.data.message);
            } else {
              self.sweetAlertError(result.data);
            }
          }, 500);

        }
      })
    },
    onInvoiceUpload(payload) {
      document.getElementById('invoice_image').src = payload.blob;
    },
  },
  created() {
    this.$store.commit(RESET_VALUES);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('general.dashboard'), route: "dashboard"},
      {title: this.$t('invoice.invoices'), route: "invoice.index"},
      {title: this.$t('invoice.edit')}
    ]);

    if (!this.isUserGranted('Invoice', ['create'])) {
      return false;
    }

    this.route_type = this.$route.query.type;
    let packing_id = this.$route.query.packing_id;
    let invoice_id = this.$route.query.id;

    let self = this, promises = [];
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/supplier-companies`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/currency`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/invoice-type`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/invoice-category`, filters: {display_in: 2}}));
    /* display_in: 2 fatura kes kısmını ifade eder, fatura kategorilerinin hangi kısımlarda görünmesi istenildği öngörülemediğinden dolayı bu işlem yapılmıştır */
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/invoice-receive-type`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/instruction-type`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: 'api/units',}));
    promises.push(this.$store.dispatch(GET_ITEMS, {
      url: 'api/vat-rates', filters: {
        type_id: 4,
      }
    }));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: 'api/vat-deducts'}));

    promises.push(this.$store.dispatch(GET_ITEMS, {url: 'api/vat-exceptions'}));

    promises.push(this.$store.dispatch(GET_ITEMS, {url: 'api/currency-rate'}));

    if (packing_id) {
      promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/following-production/packing-list/${packing_id}`}));
      promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/following-export/packing-list`}));
    }

    Promise.all(promises).then(responses => {
      if (responses[0].status) {
        self.companyOptionsRaw = responses[0].data;
        self.options.companyOptions = self.convertArrayToObjectByKey(responses[0].data, 'id', 'name_for_accounting');
      }
      if (responses[1].status) {
        self.options.currencyOptions = self.convertArrayToObjectByKey(responses[1].data, 'id', 'code');
      }
      if (responses[2].status) {
        self.options.invoiceTypeOptions = self.convertArrayToObjectByKey(responses[2].data, 'id', 'translations.0.name');
      }
      if (responses[3].status) {
        self.options.invoiceCategoryOptions = self.convertArrayToObjectByKey(responses[3].data, 'id', 'translations.0.name');
      }
      if (responses[4].status) {
        self.options.receiveTypeOptions = self.convertArrayToObjectByKey(responses[4].data, 'id', 'translations.0.name');
      }
      if (responses[5].status) {
        self.options.instructionTypeOptions = self.convertArrayToObjectByKey(responses[5].data, 'id', 'translations.0.name');
        self.instructionTypeOptionsRaw = responses[5].data;
      }
      if (responses[6].status) {
        self.options.unitOptions = self.convertArrayToObjectByKey(responses[6].data, 'id', 'logo_name');

        self.unitOptions[0] = self.options.unitOptions

        self.unitOptionsRaw = responses[6].data;
      }
      if (responses[7].status) {
        self.vatOptions = responses[7].data;
        self.options.vatOptions = self.convertArrayToObjectByKey(responses[7].data, 'id', 'vat.rate');
      }
      if (responses[8].status) {
        self.vatDeducts = responses[8].data;
        self.options.vatDeducts = self.convertArrayToObjectByKey(responses[8].data, 'id', 'code');
      }
      if (responses[9].status) {
        self.vatExceptions = responses[9].data;
        self.options.vatExceptions = self.convertArrayToObjectByKey(responses[9].data, 'id', 'code');
      }
      if (responses[10].status) {
        self.currencyRateOptions = responses[10].data.data;
      }

      if (packing_id) {
        this.form = {
          vat_deduct_id: null,
          vat_deduct_description: "",
          vat_exception_description: "",
          vat_exception_id: null,
          is_withholding: '0',
          file_names: ['image'],
          supplier_company_id: null,
          invoice_type_id: 1,
          invoice_category_id: 1,
          total_discount: null,
          withholding_amount: null,
          withholding_code: null,
          vat_rate_id: 1,
          image: null,
          invoice_date: moment().format(LARAVEL_DATE_FORMAT),
          waybill_ids: [],
          packing_list_ids: [packing_id],
          currency_unit_id: null,
          invoice_receiving_type_id: null,
          exchange_rate: null,
          vat: null,
          vat_amount: null,
          amount: null,
          kdv_sum: null,
          sum: null,
          total_sum: null,
          description: null,
        }

        if (responses[12].status) {
          self.options.packingLists = self.convertArrayToObjectByKey(responses[12].data, 'id', 'orderable.order_number');
          self.packingLists = responses[12].data;
        }

        if (responses[11].status) {
          let packingListData = responses[11].data;

          let results = [];
          packingListData.data.forEach((packingList, index) => {
            self.unitOptions[index] = self.convertArrayToObjectByKey(self.unitOptionsRaw, 'id', 'logo_name');
            let tmpData = {
              vat_rate_id: null,
              waybillable_id: null,
              waybillable_type: null,
              instructable_id: null,
              instructable_type: null,
              order_product_id: null,
              line_code: packingList.body_size,
              line_code_name: packingList.body_size,
              amount: +packingList.in_package * +packingList.box_quantity + +packingList.broken_parcel,
              amount_unit_id: 1,
              unit_price: null,
              vat_price: null,
              price: null,
              total_price: null,
              description: null,
              waybillable_ids: [],
              orderOptions: [],
              waybillable: null,
              instructable: null,
            }
            results.push(tmpData);
          })

          setTimeout(() => {
            self.transactions = results;
          }, 1000);
        }
      }
    })

    if (invoice_id) {
      this.form.invoice_type_id = 1;
      this.form.invoice_category_id = this.route_type;
      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {url: `api/invoice/` + invoice_id})
      .then(response => {
        let responseData = response.data;
        self.id = responseData.id;
        self.today = moment(responseData.created_at, LARAVEL_DATE_FORMAT);
        self.form.invoice_date = moment(responseData.invoice_date, LARAVEL_DATE_FORMAT);
        self.form.image = responseData.image_link;
        self.form.waybillable_id = responseData.waybillable_id;
        self.form.supplier_company_id = responseData.supplier_company_id;
        self.form.invoice_no = responseData.invoice_no;
        self.form.invoice_type_id = responseData.invoice_type_id;
        self.form.vat_id = responseData.vat_id;
        self.form.invoice_kind_id = responseData.invoice_kind_id;
        self.form.invoice_category_id = responseData.invoice_category_id?responseData.invoice_category_id:this.route_type;
        self.form.code = responseData.code;
        self.form.overhead_invoice = responseData.overhead_invoice;
        self.form.code_name = responseData.code_name;
        self.form.amount = responseData.amount;
        self.form.total_discount = responseData.total_discount;
        self.form.withholding_amount = responseData.withholding_amount;
        self.form.currency_unit_id = responseData.currency_unit_id;
        self.form.document_no = responseData.document_no;
        self.form.unit_amount = responseData.unit_amount;
        self.form.select_unit_id = responseData.unit_id;
        self.form.withholding_code = responseData.withholding_code;
        self.form.vat_deduct_id = responseData.vat_deduct_id;
        self.form.vat_deduct_description = responseData.vat_deduct_description;
        self.form.vat_exception_description = responseData.vat_exception_description;
        self.form.vat_exception_id = responseData.vat_exception_id;
        self.form.parent_invoice = responseData.parent_invoice?responseData.parent_invoice.invoice_no:'';
        self.form.description = responseData.description ? JSON.parse(responseData.description) : [];
        this.transactions = responseData.transactions;
      });
    } else {
      let transaction = _.cloneDeep(this.transaction);
      this.transactions.push(transaction);
    }
   
    this.getInstructionTypeCodes();
  },
  watch: {
    'form.packing_list_ids'(newValue) {
      this.transactions = [];
      let index = 0;
      if (newValue) {
        let self = this;
        let results = [];
        newValue.forEach(item => {
          let packingList = this.packingLists.find(packingListitem => +packingListitem.id === +item);
          packingList.data.forEach(packingList => {
            self.unitOptions[index] = self.convertArrayToObjectByKey(self.unitOptionsRaw, 'id', 'logo_name');
            index++;

            let tmpData = {
              vat_rate_id: null,
              waybillable_id: null,
              waybillable_type: null,
              instructable_id: null,
              instructable_type: null,
              order_product_id: null,
              line_code: packingList.body_size,
              line_code_name: packingList.body_size,
              amount: +packingList.in_package * +packingList.box_quantity + +packingList.broken_parcel,
              amount_unit_id: 1,
              unit_price: null,
              vat_price: null,
              price: null,
              total_price: null,
              description: null,
              waybillable_ids: [],
              orderOptions: [],
              waybillable: null,
              instructable: null,
            }
            results.push(tmpData);
          })
        });
        setTimeout(() => {
          self.transactions = results;
        }, 500);
      }
    },
    'form.waybill_ids'(newValue) {
      if (newValue) {
        let self = this;
        // this.transactions = [];
        let currentTransactionIndex = this.transactions.length;
        newValue.forEach(item => {
          let waybill = this.waybills.find(waybillItem => +waybillItem.id === +item);
          waybill.transactions.forEach((transactionItem, index) => {
            let tmpItem = {
              waybill_id: waybill.id,
              vat_rate_id: null,
              waybillable_id: transactionItem.waybillable_id,
              waybillable_type: transactionItem.waybillable_type,
              instructable_id: transactionItem.instructable_id,
              instructable_type: transactionItem.instructable_type,
              order_product_id: transactionItem.order_product_id,
              line_code: transactionItem.product_name,
              instruction_type_id: transactionItem.instruction_type_id,
              line_code_name: transactionItem.product_name,
              amount: transactionItem.net_amount,
              amount_unit_id: transactionItem.unit_id,
              unit_price: transactionItem.instructable.price,
              vat_price: null,
              price: null,
              total_price: null,
              description: null,
              waybillable_ids: [],
              orderOptions: [],
              waybillable: transactionItem.waybillable,
              instructable: transactionItem.instructable,
              waybill_transaction_id:transactionItem.id,
            }

            self.unitOptions[currentTransactionIndex] = self.convertArrayToObjectByKey(self.unitOptionsRaw, 'id', 'logo_name');

            currentTransactionIndex++;

            let prefix = "S-";
            if (tmpItem.waybillable_type == "\\App\\Models\\Order") {
              prefix = "O-";
            }

            let tmpId = prefix + tmpItem.waybillable_id.toString();

            // setTimeout(() => {
            //   self.waybillableIdsChanged({
            //     newValue: [tmpId],
            //   }, index);

            // }, 1000)
              tmpItem.waybillable_ids = [tmpId.toString()];

            this.transactions.push(tmpItem);
          })
        });

      }
    },
    transactions: {
      handler: function (value, oldValue) {
        if (value && value.length) {
          value.map(item => {
            let rate = this.options.vatOptions[item.vat_rate_id];

            item.price = item.amount * item.unit_price;
            item.vat_price = item.price * rate / 100;
            item.total_price = item.price + item.vat_price;
            item.vat_rate = rate;

            return item;
          })
        }
   
      },
      deep: true
    },
    'form.supplier_company_id'(newValue) {
      if (+newValue !== 0) {
        if (!(this.form.packing_list_ids.length || this.route_type )) {
          this.transactions = [];
          this.form.waybill_ids = [];
        }
        let selectedCompany = this.companyOptionsRaw.find(item => item.id === +newValue);
        this.form.currency_unit_id = selectedCompany.currency_unit_id;
        this.getWaybills();
  
      }
    },
    'form.vat_deduct_id'(value) {
      let item = this.vatDeducts.find(item => +item.id === +value);
      this.form.vat_deduct_description = item.name;
   
    },
    'form.vat_exception_id'(value) {
      let item = this.vatExceptions.find(item => +item.id === +value);
      this.form.vat_exception_description = item.name;
 
    },
    'form.invoice_category_id'(value) {
      let self = this;
      if (+value === 3)
        self.is_service = false;

      if ( +value === 1  ) {
        let tmpData = self.instructionTypeOptionsRaw.filter(item => +item.type === 1 || +item.type === 3);
        self.options.instructionTypeOptions = self.convertArrayToObjectByKey(tmpData, 'id', 'translations.0.name');
      }
      

      if (+value === 2 ) {
        let tmpData = self.instructionTypeOptionsRaw.filter(item => +item.type === 2);
        self.options.instructionTypeOptions = self.convertArrayToObjectByKey(tmpData, 'id', 'translations.0.name');
      }
 
    }
  }
}
</script>

<style lang="scss" scoped>
.h-50vh {
  height: 50vh !important;
}

.h-20px {
  height: 20px !important;
}
</style>
